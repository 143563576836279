@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins-Regular.ttf") format("truetype");
  /* Add other font formats (.woff, .woff2, etc.) here if you have them */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins-Bold.ttf") format("truetype");
  /* Add other font formats (.woff, .woff2, etc.) here if you have them */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins-SemiBold.ttf") format("truetype");
  /* Add other font formats (.woff, .woff2, etc.) here if you have them */
  font-weight: 600;
  font-style: normal;
}

.columns-container {
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
  flex: 1;
}

.selected-section {
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-top: -30px;
  border-radius: 25px;
  border: 1px #cccccc;
  background-color: #fff;
  padding: 35px;
  width: 70%;
}

.settings-menu {
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-top: -30px;
  border-radius: 25px;
  border: 1px #cccccc;
  background-color: #fff;
  padding: 35px;
  width: 30%;
}

.menu-item {
  font-family: Poppins;
  font-size: 20px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  color: #6b7280;
}

.menu-item:hover {
  background-color: #f5f5f5;
}

.active {
  color: #546bea;
  background-color: #f5f5f5;
}

.menu-item-icon {
  margin-right: 10px;
}

.menu-item-title {
}

.display-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}
.display-label {
  font-family: Poppins;
  font-size: 20px;
  margin-right: 10px;
}

.display-value {
  font-family: Poppins;
  font-size: 24px;
  margin-right: 2em;
}

.title {
  font-family: Poppins;
  color: #6b7280;
}
