.date-picker {
    border-radius: 8px;
    border: 2px solid #F5F5F5;
    padding: 10px;
    padding-right: 50px;
    font-family: Poppins;
    min-width: 250px;
}

.clear-button::after {
    background: #5B6DEC
}

.default-day {
    width: 1.7rem;
    font-family: Poppins;
}

.selected-day {
    background: #DEE3FF;
    color: black;
    margin: 0px;
    padding: 2.656px;;
    border-radius: 0px;
    width: 2rem;
    font-family: Poppins;
    text-align: center;
}


.start-day {
    background: #5B6DEC;
    color: white;
    border-bottom-left-radius: 0.3rem;
    border-top-left-radius: 0.3rem;

}

.end-day {
    padding: 1.656px;
    background: white;
    border: 1px solid #5B6DEC;
    border-bottom-right-radius: 0.3rem;
    border-top-right-radius: 0.3rem;

}

