.generate-iban-btn {
  width: 40%;
  background-color: #546bea;
  padding: 1rem;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  margin: 1.5em 30% 0em 30%;
}

.account-info {
  padding: 0.7em;
  margin: 0 0.5em;
  border-bottom: 1px solid gray;
  font-size: 16px;
}
.account-info label {
  font-weight: 600;
}
