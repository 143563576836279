.acc-info {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 100;
  display: flex;
}

.account {
  font-family: Poppins;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  padding: 2em;
  margin: 2em;
}

.account:hover {
  background-color: #f5f5f5;
}
