.login-screen {
  display: flex;
  height: 100vh;
  background-image: url("../assets/loginBackground.png");
  background-size: cover;
  align-items: center;
  justify-content: center;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 10px;
}

.form-container label,
.form-container input[type="submit"] {
  width: 100%;
}

.form-container label {
  margin-bottom: 1rem;
  text-align: left;
}

.form-container input[type="tel"],
.form-container input[type="password"],
.form-container input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px solid gray;
  font-size: 16px;
}

.form-container button[type="submit"] {
  width: 100%;
  background-color: #546bea;
  padding: 1rem;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

#error-message {
  color: red;
  font-size: 14px;
}
