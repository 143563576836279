.add-contract-btn {
  margin: 1rem 0rem;
  width: 30%;
  background-color: #546bea;
  padding: 1rem;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.contract-fields {
  margin-top: 1rem;
  width: 30%;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid gray;
  font-size: 16px;
}
