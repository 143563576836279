.transfer-form-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 100;
  font-size: 19px;
  line-height: 20px;
  color: #44494b;
  margin-bottom: 10px;
}

.transfer-form-input {
  font-family: Poppins;
  font-style: normal;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid grey;
  font-size: 18px;
  border-radius: 10px;
}

.transfer-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
  width: 372px;
  height: 68px;
  background: #5b6dec;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 20px;
  border-color: transparent;
}

.success-btn {
  margin-top: 10;
  width: 100%;
  padding: 10px;
  background-color: #546bea;
  color: white;
  border-radius: 10px;
  font-size: 18;
  border: none;
  font-family: Poppins;
}
