.page {
  margin-left: 7rem;
}

.title {
  top: 100px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  /* identical to box height */
  color: #414141;
}
