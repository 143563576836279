@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.ttf") format("truetype");
  /* Add other font formats (.woff, .woff2, etc.) here if you have them */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Bold.ttf") format("truetype");
  /* Add other font formats (.woff, .woff2, etc.) here if you have them */
  font-weight: bold;
  font-style: normal;
}
/*
body > #root > div > div > div  {height:100vh;} */

.account-card {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px;
  left: calc(50% - 561px / 2);
  top: 0px;
  height: 160px;

  background: linear-gradient(
    111.49deg,
    rgba(255, 255, 255, 0.4) -8.95%,
    rgba(255, 255, 255, 0.01) 114%
  );
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 25px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.account-card-balance-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 42px;
  /* identical to box height */
  color: #ffffff;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: -5px 0px;
}

.account-card-amount {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2em;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.welcome-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
}

.see-all-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 10px;
  left: calc(50% - 112px / 2 + 188.5px);
  top: calc(50% - 26px / 2 - 165.5px);

  background: #f5f5f5;
  border-radius: 18px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #9caeb8;
  border: 0;
}
