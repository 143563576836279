.sidebar-container {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 7rem;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  color: #6b7280;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  /* Add padding to the top of the Sidebar to prevent cutting off the top */
  padding-top: 1rem;
}

.sidebar-items {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.avatar-circle {
  /* Add styles for the avatar circle */
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #f3f4f6; /* Update the background color to a lighter grey */
  margin: 0.5rem auto; /* Center the avatar circle horizontally */
  margin-bottom: 100px;
  margin-top: 41px;
}

.sidebar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  /* Add margin bottom for spacing between items */
  margin-bottom: 5px;
}

.sidebar-item:hover,
.sidebar-item.active {
  /* Change background color when hovered or active */
  background-color: #ffffff;
}

.sidebar-item:hover .sidebar-icon svg path,
.sidebar-item.active .sidebar-icon svg path {
  /* Change color of icon path when hovered or active */
  fill: #546bea;
}

.sidebar-item:hover .text-sm,
.sidebar-item.active .text-sm {
  /* Change color of text when hovered or active */
  color: #546bea;
}

.sidebar-icon {
  /* Add styles for the icon container */
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.text-sm {
  /* Add styles for the text */
  font-size: 0.875rem;
  /* Position the text on top of the icon */
  margin-top: -0.25rem;
}
