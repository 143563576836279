.display-mode {
  display: "flex";
  flex-direction: "row";
  align-items: "center";
  padding: "0px 35px";
}

.transactions-title {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 600;
  font-size: "20px";
  color: "#44494B";
}
