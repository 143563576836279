@import url("https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.3/css/bulma.min.css");

.container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.input-group {
  margin-top: 40px;
  margin-bottom: 20px;
  align-items: center;
}

.label {
  font-size: 1.2rem;
  margin-bottom: 10px;
  display: block;
}

.input {
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
}

.button.is-link {
  margin-right: 10px;
}

.button.is-active {
  background-color: #3273dc;
  border-color: #3273dc;
}

.button.is-outlined {
  background-color: transparent;
  border-color: #3273dc;
  color: #3273;
}
