.contact {

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
    border-bottom: 1px solid #EDEDED;
    border-radius: 10px;

}

.contact:hover {

    background-color: #EDEDED;

}
