@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.ttf") format("truetype");
  /* Add other font formats (.woff, .woff2, etc.) here if you have them */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Bold.ttf") format("truetype");
  /* Add other font formats (.woff, .woff2, etc.) here if you have them */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.ttf") format("truetype");
  /* Add other font formats (.woff, .woff2, etc.) here if you have them */
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  /* Add other font formats (.woff, .woff2, etc.) here if you have them */
  font-weight: semiBold;
  font-style: normal;
}

.amount-style {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;

  font-size: 36px;
  line-height: 54px;
  margin-left: 20px;
  margin-top: 20px;
  /* identical to box height */

  color: #546bea;
}

.fine-amount {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  color: #5b5b5b;
  text-align: left;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: red;
}

.details-icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
