@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins-Regular.ttf") format("truetype");
    /* Add other font formats (.woff, .woff2, etc.) here if you have them */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins-Bold.ttf") format("truetype");
    /* Add other font formats (.woff, .woff2, etc.) here if you have them */
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins-SemiBold.ttf") format("truetype");
    /* Add other font formats (.woff, .woff2, etc.) here if you have them */
    font-weight: 600;
    font-style: normal;
}


.vendos-shm {
    font: Poppins;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */

    color: #44494b;
}

.depozito {
    font-family: "Poppins";
}

.textbox {
    background: #ffffff;
    border: 1px solid #546bea;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
}

p {
    margin: 0px 20px;
}


.card-item {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    background: #f8f8f8;
    border: 1px solid rgba(114, 181, 168, 0.4);
    box-shadow: -3px 4px 25px rgba(114, 181, 168, 0.15);
    border-radius: 20px;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px;
    color: #969696;
    margin-bottom: 10px;
    padding: 30px;
}


.add-new {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 16px;
    color: #72b5a8;
    margin: 20px 0px

}

.continue {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    background: #546eea;
    border-radius: 10px;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    border: none;
}

.columns-container {
    display: flex;
    flex-direction: row;
    background-color: #F5F5F5;
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 20px;
    margin-top: -30px;
    border-radius: 25px;
    border: 1px #cccccc;
    background-color: #FFF;
    padding: 35px;

}

.bank-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px 26px;
    gap: 10px;

    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(84, 107, 234, 0.1);
    border-radius: 8px;
    border-color: transparent;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    border-color: transparent;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.bank-two {
    width: 100%;
    background: #ffffff;
    box-shadow: 2px 4px 53px rgba(84, 107, 234, 0.1);
    border-color: transparent;
    border-radius: 25px;
    text-align: start;
    padding: 35px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */


    color: #5F6567;


}

.emri-llog {
    margin: 0px;
    color: #546bea;
    text-align: start;
    font-weight: 300;
    font-size: 12px;
}

