@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.ttf") format("truetype");
  /* Add other font formats (.woff, .woff2, etc.) here if you have them */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Bold.ttf") format("truetype");
  /* Add other font formats (.woff, .woff2, etc.) here if you have them */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.ttf") format("truetype");
  /* Add other font formats (.woff, .woff2, etc.) here if you have them */
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  /* Add other font formats (.woff, .woff2, etc.) here if you have them */
  font-weight: 600;
  font-style: normal;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.modal-content {
  background-color: white;
  /* border: 0.1px solid #546BEA; */
  border-radius: 10px;
  padding: 20px;
  /* box-shadow: 0px 0px 60px lightgrey; */
  border: 1px solid rgba(84, 107, 234, 0.5);
  max-width: 500px;
}

h1 {
  font-size: 1.5em;
  margin-bottom: 20px;
  font-weight: 600;
  font-family: Poppins;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.modal-header h3 {
  margin: 0;
  flex-grow: 1;
}

.modal-header button {
  background: none;
  border: none;
}
